<template>
  <div class="pa-0 mx-lg-auto">
    <div>
      <!-- <div v-if="items[1]">  <v-progress-linear
      :value="onSaleRatio"
 background-color="secondary"
      height="20" class="pb-5"
    ></v-progress-linear>--><!--<v-row class="overline mx-2 pa-2 text-left font-weight-thin">{{items.length}} Items available<v-spacer/>{{totalItems}} items on TRST </v-row></div>-->
      <div>
        <v-row>
          <v-col class="text-center ma-auto" cols="3"
            ><v-img
              src="img/design/1.png "
              lazy-src="img/design/1m.jpeg"
              max-height="200"
              class="rounded-br-xl contain mx-auto"
            >
            </v-img
          ></v-col>
          <v-col cols="6">
            <h1 class="text-center">Trustless Items Marketplace</h1>
            <h2 class="display-6 text-white text-center font-weight-medium">
              Assets <v-icon size="30">mdi-swap-horizontal </v-icon> Tokens
            </h2>
            <p class="caption text-center">Trade using independent prices</p>

            <p class="caption font-weight-light text-center" v-if="!exp">
              On the Trustless Items Marketplace, you can trust that the price
              is right. After payment, your deposit is stored on a
              middle-account, for free. Use independent information and a
              history on the TRST blockchain to your advantage.
              <v-btn icon x-small to="/faq"
                ><v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </p></v-col
          ><v-col cols="3" class="ma-auto"
            ><v-img
              src="img/design/2.png "
              max-height="200"
              lazy-src="img/design/2m.jpeg"
              class="rounded-bl-xl"
            >
            </v-img></v-col
        ></v-row>

        <v-container class="">
          <v-row>
            <v-col cols="12" sm="7">
              <buy-search-bar />
            </v-col>
            <v-col cols="10" sm="4">
              <buy-options-bar :items="items" />
            </v-col>
            <v-col cols="2" sm="1" class="ml-n1">
              <v-btn fab class="mt-1" small @click="showAdvanced">
                <v-icon> mdi-tune </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <buy-tag-bar :advanced="advanced" />
        </v-container>
        <v-chip-group multiple show-arrows active-class="primary--text"
          ><div v-for="tag in tags" :key="tag">
            <v-chip
              color="primary lighten-2 text-capitalize"
              :to="{ name: 'SearchTag', params: { tag: tag.toLowerCase() } }"
              ><v-icon small left> mdi-tag-outline </v-icon>{{ tag }}
            </v-chip>
          </div>
        </v-chip-group>
        <div v-if="items[0]">
          <div class="pl-4">
            <v-btn icon x-small to="/faq"
              ><v-icon>mdi-information-outline</v-icon>
            </v-btn>
            <span class="caption" v-if="items[1]">
              {{ items.length }} items available</span
            >
          </div>
          <v-row class="pa-0 ma-0">
            <v-col :key="i" cols="12" md="6" v-for="(item, i) in items">
            
                  <buy-item-item-info :itemid="item.id" />
            </v-col>
          </v-row>
        </div>
        <v-card
          @click="clearList()"
          color="secondary lighten-3 "
          class="rounded-xl outlined elevation-1 text-center ma-4 pa-2"
          v-if="items.length < 1"
        >
          <v-card-text class="caption">
            No items to show. Use search / filters to find items. </v-card-text
          ><v-icon> mdi-refresh </v-icon>
        </v-card>
        <v-img
          max-height="220"
          class="rounded-b-xl ma-0 pa-0"
          src="img/design/market.png "
          lazy-src="img/design/markettp.jpeg"
        />
      </div>
    </div>
  </div>
</template>


<script>
import BuyItemItemInfo from "./BuyItemItemInfo.vue";
import BuySearchBar from "./BuySearchBar.vue";
import BuyOptionsBar from "./BuyOptionsBar.vue";
import BuyTagBar from "./BuyTagBar.vue";
export default {
  components: {
    BuyItemItemInfo,
    BuySearchBar,
    BuyOptionsBar,
    BuyTagBar,
  },
  data: function () {
    return {
      advanced: false,
      isActive: false,

      tags: [
        "NFT",
        "Watch",
        "Shoes",
        "Clothing",
        "Collectable",
        //  "Garden item",
        "Vehicle",
        // "Motor",
        //"Sport",
        "Book",
        // "Antique",
        "Computer",
        "Smartphone",
        "Smart Device",
        "Sound Device",
        "TV",

        "Other",
      ],
    };
  },

  computed: {
    items() {
      let list = this.$store.getters.getTotalBuyItems || [];
      console.log(list);
      if (list[0]) {
        return list;
      } else {
        this.$store.dispatch("setBuyItemList");
        return this.$store.getters.getTotalBuyItems;
      }
    },
    totalItems() {
      if (this.items) {
        return this.$store.state.data.item.length;
      }
    },
  },

  mounted() {
    //this.$store.dispatch("setBuyItemList");
  },

  methods: {
    showAdvanced() {
      this.advanced = !this.advanced;

      this.$store.dispatch("setSortedTagList");
      this.$store.dispatch("setSortedLocationList");
    },
    clearList() {
      this.$store.dispatch("setBuyItemList");
    },
  },
};
</script>


