<template>
  <div >
   
    
    <v-select  class="mx-auto rounded-xl"
             
             v-model="selectedFilter"
             v-on:input="search()"
              :menu-props="{ offsetY: true }"
              solo
              item-text="filter"
              
              hide-details
              :items="filters"
              prepend-inner-icon="mdi-filter"
              label="Sort by"
            ></v-select>

            
  </div>
  
</template>


<script>

import ItemListBuy from "./ItemListBuy.vue";
export default {
  props: ["items"],
  components: { ItemListBuy },
  data: function () {
    return {
      selectedFilter: "All",
    filters: [
          { filter: 'All',  },
          { filter: 'Direct transfer', },
          { filter: 'Shipping', },
          { filter: 'Trusted Price', },
          { filter: 'Reposted', },
 
        ],

  };
  },
  
    

  methods: {


    search() {
      console.log(this.selectedFilter);
      if (this.selectedFilter == "Shipping") {
     let input = this.$store.state.data.item.filter(item => item.buyer === "" && item.transferable === true && item.shipping_cost > 0);     
      this.$store.dispatch("filterBuyItemList", input);
      };
       if (this.selectedFilter == "Trusted Price") {
     let input = this.$store.state.data.item.filter(item => item.buyer === "" && item.transferable === true && item.seller == item.creator);     
      this.$store.dispatch("filterBuyItemList", input);
      };
       if (this.selectedFilter == "Reposted") {
     let input = this.$store.state.data.item.filter(item => item.buyer === "" && item.transferable === true && item.seller != item.creator);     
      this.$store.dispatch("filterBuyItemList", input);
      };
      if (this.selectedFilter == "Direct transfer") {
        this.$store.dispatch("setBuyItemListToDirectTransfer"); };
      if (this.selectedFilter == "All") {
        this.$store.dispatch("setBuyItemList");
      
      
      
      
    };},

   
  },
};
</script>
